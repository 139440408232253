import React, {HTMLAttributes, useMemo} from 'react';
import {ReactSVG} from 'react-svg';
import classNames from 'classnames';

import IconClose from '../../../../assets/icon-cross-gray.svg?url';
import Button from '../../../atoms/Button';

import './DialogClose.scss';

export interface DialogCloseProps extends HTMLAttributes<HTMLButtonElement> {
  onClose(): void;
}

const DialogClose = (props: DialogCloseProps) => {
  const {onClose, className, ...buttonProps} = props;

  const fullClassName = useMemo(() => classNames('DialogClose', className), [className]);

  return (
    <Button {...buttonProps} className={fullClassName} flavor="regular" taste="line" onClick={onClose}>
      <ReactSVG className="DialogClose-icon" src={IconClose} />
    </Button>
  );
};

export default DialogClose;
