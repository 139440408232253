import React, { HTMLAttributes, useContext, useMemo } from 'react';

import NavLinkContext from '../../../libs/navlink-context';

import StatsIcon from '../../../assets/stats-icon.svg';

import './ProjectsCard.scss';

type ProjectsCardProps = {
  maxSize?: number,
  size: number,
  title: string,
  to: string,
} & HTMLAttributes<HTMLAnchorElement>;

const ProjectsCard = (props: ProjectsCardProps) => {
  const { maxSize = 4, size, title, to, ...anchorAttr} = props;

  const NavLink = useContext(NavLinkContext);

  const progressWidth = useMemo(() => Math.round(Math.min(size / maxSize, 1) * 100), [size, maxSize]);

  return (
    <NavLink { ...anchorAttr } className="projects-card" to={to} >
      <h3 className="projects-card__title">{title}</h3>
      <div className="projects-card__size">
        <span className="projects-card__size-text">
          {size}
        </span>
        <img className="projects-card__icon" src={StatsIcon} />
      </div>
      <div className="projects-card__progress-container" style={{ width: progressWidth }}>
        <div className="projects-card__progress" />
      </div>
    </NavLink >
  );
};

export default ProjectsCard;
