import React, { useMemo, useState, useEffect, useCallback, HTMLAttributes } from 'react';
import { setTimeout, clearTimeout } from 'requestanimationframe-timer';
import moment from 'moment';
import classNames from 'classnames';

import Button from '../../atoms/Button';

import IconRequest from '../../../assets/icon-request.svg';

import './index.scss';

export interface CountDownInterface extends HTMLAttributes<HTMLDivElement> {
  className?: string,
  cooldownMs: number,
  onRequestCode: () => void,
  requestText?: string,
  youCanRequestText?: string,
}

/**
 * This component displays request code button which has a callback, when pressing we trigger
 * our count down based on coolDownMs which makes the button disabled till the countdown is over
 */
const RequestCode = (props: CountDownInterface) => {
  const {
    className,
    cooldownMs,
    onRequestCode,
    requestText = 'Request new code',
    youCanRequestText = 'You can request a new code in',
    ...divAttr
  } = props;

  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (timeLeft === 0) return;
    const timeout = setTimeout(() => {
      setTimeLeft((count) => count - 1)
    }, 1000);
    return () => {
      if (timeout) clearTimeout(timeout)
    };
  }, [timeLeft, setTimeLeft]);

  // properly format countdown
  const countDown = useMemo(() => {
    const duration = moment.duration(timeLeft, 'seconds');
    return moment(duration.asMilliseconds()).format('mm:ss');
  }, [timeLeft]);

  const triggerCountDown = useCallback(() => {
    // convert to seconds
    setTimeLeft(cooldownMs / 1000);
    onRequestCode();
  }, [cooldownMs, setTimeLeft]);

  return (
    <div {...divAttr} className={classNames('RequestCode', { className })}>
      {timeLeft !== 0 &&
        <p className="RequestCode-text">
          {youCanRequestText} {countDown}
        </p>
      }
      <div className="RequestCode-wrapper">
        <Button
          taste="boundless"
          disabled={timeLeft !== 0}
          onClick={triggerCountDown}>
          <img
            alt="Icon"
            className="spacing right__is-5 left__is-5"
            src={IconRequest}
          />
          {requestText}
        </Button>
      </div>
    </div>
  );
};

export default RequestCode;
