
import React, {useMemo, memo, HTMLAttributes} from 'react';
import {ReactSVG} from 'react-svg';

import classNames from 'classnames';

import Button from '../../atoms/Button';

import iconStar from '../../../assets/icon-star.svg';

import './StarRating.scss';


interface StarProps extends HTMLAttributes<HTMLElement> {
  index: number;
  filled: boolean;
  disabled?: boolean;
};

const Star = (props: StarProps) => {
  const {index, filled, disabled, ...elementProps} = props;

  const starClassName = useMemo(() => {
    return classNames('icon-star', {
      'is-filled': filled,
      'is-pointer': !disabled,
    });
  }, [filled, disabled]);

  return (
    <Button
      disabled={disabled}
      className={starClassName}
      {...elementProps}
      >
      <ReactSVG
        data-testid={`star-${index}`}
        src={iconStar}
      />
    </Button>

  );
};

export default memo(Star)
