import {Dispatch, RefObject, useEffect} from 'react';

interface UseOutsideClick {
  dropdownRef: RefObject<HTMLDivElement>;
  isDropdownShown: boolean;
  setIsDropdownShown: Dispatch<boolean>;
}

const useDropdownClickOutside = (props: UseOutsideClick) => {
  const {dropdownRef, isDropdownShown, setIsDropdownShown} = props;

  useEffect(
    function onOutsideClick() {
      const eventListener = (event: MouseEvent) => {
        if (!dropdownRef.current?.contains(event.target as Node)) {
          setIsDropdownShown(false);
        }
      };

      if (isDropdownShown) {
        document.body.addEventListener('click', eventListener);
      }
      return () => document.body.removeEventListener('click', eventListener);
    },
    [isDropdownShown],
  );
};

export default useDropdownClickOutside;
