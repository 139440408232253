import React from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import classNames from "classnames";
import Button from '../../atoms/Button';

import IconClose from "!@svgr/webpack!../../../assets/icon-error.svg";
import IconClip from "!@svgr/webpack!../../../assets/icon-clip.svg";

import "./UploadArea.scss";

const VALID_EXT_TYPES = ['png', 'gif', 'jpeg', 'jpg', 'bmp'];
const MAX_FILE_SIZE = 10485760;

type UploadAreaType = {
  onChange: (imagesList: ImageListType) => void;
  maxUploads: number;
  imagesList: ImageListType;
}

const UploadArea = (props: UploadAreaType) => {
  const { onChange, maxUploads, imagesList } = props;
  return (
    <div className="UploadArea">
      <ImageUploading
        multiple
        value={imagesList}
        onChange={onChange}
        maxNumber={maxUploads}
        acceptType={VALID_EXT_TYPES}
        maxFileSize={MAX_FILE_SIZE}>
        {({
          imageList,
          onImageUpload,
          onImageRemove,
          dragProps,
          errors,
        }) => (
          <div {...dragProps} className={classNames('UploadArea-wrapper', {
            'has-Previews': imageList.length > 0
          })}>
            <div className="UploadArea-previews">
              {imageList.map((image, index) => (
                <div key={`preview${index}`} className="UploadArea-imageItem">
                  <img className="UploadArea-imagePreview" src={image.dataURL} alt="" width="100" />
                  <button className="UploadArea-removeButton" onClick={() => onImageRemove(index)}>
                    <IconClose />
                  </button>
                </div>
              ))}
              {errors &&
                <div className="UploadArea-errors">
                  <IconClose />
                  {errors.maxNumber && <span>Number of selected images exceed maxNumber: {maxUploads}</span>}
                  {errors.acceptType && <span>Your selected file type is not allowed</span>}
                  {errors.maxFileSize && <span>Selected file size exceed 10MB</span>}
                </div>}
            </div>
            <div className="UploadArea-buttonArea">
              <Button
                className="UploadArea-attachButton"
                flavor="regular"
                size="small"
                taste="line"
                type="button"
                onClick={onImageUpload}
                disabled={imageList.length >= maxUploads}
                {...dragProps}>
                <IconClip /> Attach Image
              </Button>
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  )
}

export default UploadArea;
