import React, {useCallback, useState, useMemo, HTMLAttributes} from 'react';
import classNames from 'classnames';

import DropdownList from 'components/molecules/DropdownList';
import DropdownButton from '../../molecules/DropdownButton';
import PopUp from 'components/atoms/PopUp';
import {DropdownButtonInterface} from '../../molecules/DropdownButton/types';
import {useIsMobile} from 'components/utils/useResponsiveBreakpoint';

import iconOptions from '../../../assets/icon-options.svg';

import './index.scss';

interface MobileDropdownButtonProps extends DropdownButtonInterface {
  onDismissPopup?(): void;
}

/**
 * This component shows a dropdown when clicked, items should trigger any
 * desired action using onClick event
 * @param props {MobileDropdownButtonProps}
 * @constructor
 */
const MobileDropdownButton = (props: MobileDropdownButtonProps & HTMLAttributes<HTMLDivElement>) => {
  const {onDismissPopup, ...otherProps} = props;
  const [isDropdownShown, setShowDropdown] = useState<boolean>(false);

  const isMobile = useIsMobile()

  const list = useMemo(() => (
    otherProps.items.map((item) => {
      return {
        label: item.label,
        icon: item?.icon,
        onClick: () => {
          item.onClick?.();
          setShowDropdown(false);
        },
      };
    })
  ), [otherProps.items]);

  const onShowDropdown = useCallback(() => {
    if (isMobile) {
      setShowDropdown(prevValue => !prevValue);
    }
  }, [isMobile]);

  const onDropdownDismiss = useCallback(() => {
    setShowDropdown(false);
  }, []);

  const onMobilePopupDismiss = useCallback(() => {
    setShowDropdown(false);
    onDismissPopup?.();
  }, [onDismissPopup]);

  const MobileDropdownButtonClassName = useMemo(() => {
    return classNames('MobileDropdownButton', otherProps.className);
  }, [otherProps.className]);

  return (
    <DropdownButton {...otherProps} className={MobileDropdownButtonClassName} icon={iconOptions} {...{onShowDropdown, onDropdownDismiss}}>
      <PopUp className="MobileDropdownButton-popup" onHide={onMobilePopupDismiss} shouldBlockBodyScroll={false} showCloseIcon={false} visible={isDropdownShown}>
        <DropdownList className="card shadow__z2" list={list} />
      </PopUp>
    </DropdownButton>
  );
};

export default MobileDropdownButton;
