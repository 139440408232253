import React, { HTMLAttributes } from 'react';
import './MenuRowSimple.scss'

export interface MenuRowSimpleProps extends HTMLAttributes<HTMLDivElement> {
 title: string;
 leftNode?: React.ReactNode;
 rightNode?: React.ReactNode; 
 isBottomBordered?: boolean;
 isActive?: boolean;
}

const MenuRowSimple: React.FC<MenuRowSimpleProps> = ({ 
 title,
 leftNode,
 rightNode,
 isBottomBordered,
 isActive,
 ...props
}) => {
 return (
   <div 
     className={`menu-row-simple ${isBottomBordered ? 'menu-row-simple__bordered' : ''} ${isActive? 'menu-row-simple__active' : ''}`}
     {...props}
   >
     <div className='menu-row-simple__left-content'>
       <div className='menu-row-simple__content-leftnode'>
         {leftNode}
       </div>
       <div className='menu-row-simple__text'>
         <span>{title}</span>
       </div>
     </div>
     <div className='menu-row-simple__content-rightnode'>
       {rightNode}
     </div>
   </div>
 );
};

export default MenuRowSimple;