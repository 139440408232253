import React, { useEffect, useMemo, useRef, useState, useCallback, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import DropdownList from '../DropdownList';
import { ErgSelectInterface, OptionType } from './types';

import iconArrow from '../../../assets/icon-arrow-down.svg';

import './index.scss';

/**
 * This component shows a dropdown of select options and calls `props.onChange`
 * when one of them is clicked. In general, this component mimics the react-select interface.
 * @param props {ErgSelectInterface}
 * @constructor
 */
const ErgSelect = (props: ErgSelectInterface & HTMLAttributes<HTMLDivElement>) => {
  const { onChange, options, className = '', placeholder = 'Select…', value, ...divAttr } = props;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [activeValue, setActiveValue] = useState<OptionType | undefined>(value);
  const [isDropdownShown, setShowDropdown] = useState<boolean>(false);

  useEffect(function onValueChange() {
    if (activeValue) {
      onChange?.(activeValue);
    }
  }, [activeValue, onChange]);

  useEffect(function onOutsideClick() {
    const eventListener = (event: MouseEvent) => {
      if (dropdownRef.current?.contains(event.target as Node)) {
        // not an outside click
      } else {
        setShowDropdown(false);
      }
    };

    if (isDropdownShown) {
      window.addEventListener('click', eventListener);
    }
    return () => window.removeEventListener('click', eventListener);
  }, [isDropdownShown]);

  const list = useMemo(() => options.map(item => {
    return {
      label: item.label,
      icon: item?.icon,
      onClick: () => {
        setActiveValue(item);
        item.onClick?.();
        setShowDropdown(false);
      },
    }
  }), [setShowDropdown, setActiveValue]);

  const triggerDropdown = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setShowDropdown(!isDropdownShown);
  }, [isDropdownShown]);

  const dropDownWrapperClasses = useMemo(() => classNames({
    'erg-select--dropdown': true,
    'visible': isDropdownShown,
  }), [isDropdownShown]);

  const ergSelectClasses = useMemo(() => classNames({
    'erg-select': true,
    'active': isDropdownShown,
  }), [isDropdownShown]);

  return (
    <div { ...divAttr } className={className}>
      <div className="erg-select--wrapper">
        <div className={ergSelectClasses} onClick={triggerDropdown}>
          {activeValue?.label || <span className="placeholder">{placeholder}</span>}
          <ReactSVG className="erg-select--arrow" src={iconArrow} />
        </div>
      </div>
      {isDropdownShown &&
        <div ref={dropdownRef} className={dropDownWrapperClasses}>
          <DropdownList
            className="card shadow__z2"
            list={list} />
        </div>
      }
    </div>
  )
}

export default ErgSelect;
