import React, { useState } from 'react';
import classNames from 'classnames';
import './index.scss';

export type NotificationProps = {
  children?: React.ReactNode;
  className?: string;
  mode?: 'floating' | 'embed' | 'transparent';
  onClick?: () => void;
  onClose?: () => void;
  type?: 'Error' | 'Warning' | 'Information' | 'Success';
};

const Notification = ({
  children,
  className,
  mode = 'floating',
  onClick,
  onClose,
  type = 'Success',
}: NotificationProps) => {
  const [display, setDisplay] = useState(true);

  const close = () => {
    setDisplay(false);
  };

  const floating = mode === 'floating';
  const embed = mode === 'embed';
  const transparent = mode === 'transparent';
  const classes = classNames({
    'notification-container': true,
    'notification-container__display': display,
    'notification-container__floating': floating,
    'notification-container__embed': embed,
    'notification-container__transparent': transparent,
    'error': type === 'Error',
    'warning': type === 'Warning',
    'success': type === 'Success',
    'info': type === 'Information',
    [className || '']: className,
  });

  const clickByCross = onClose ? onClose : close;
  const Tag = onClick ? 'button' : 'div';

  return (
    <Tag className={classes} onClick={onClick}>
      <div>
        {floating && <span className="notification-container__close" onClick={clickByCross} />}
        <div>
          <div className="notification-container__description">{children}</div>
        </div>
      </div>
    </Tag>
  );
};

export default Notification;
