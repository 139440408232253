import isNil from "lodash/isNil";

export const controlNumberRange = (v: string, min: number = 0, max?: number): number | string => {
  if (v.length === 0) return min;
  let value = Number(v);
  if (value < min) return min;
  if (!isNil(max) && (value > max)) return max;
  return value;
};

