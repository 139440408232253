import {useMemo} from 'react';

import yelpLogo from '../../../assets/testimonial-icons/yelp.svg';
import googleLogo from '../../../assets/testimonial-icons/google.svg';
import angiLogo from '../../../assets/testimonial-icons/angi.svg';
import thumbtackLogo from '../../../assets/testimonial-icons/thumbtack.svg';
import bbbLogo from '../../../assets/testimonial-icons/bbb.svg';
import {RatingKey, RatingDataProps} from './types';

import DEFAULT_RATING_DATA from '../../../data/ratings.json';

const RATING_LOGO_DATA: Record<RatingKey, string> = {
  google: googleLogo,
  homeadvisor: angiLogo,
  yelp: yelpLogo,
  thumbtack: thumbtackLogo,
  bbb: bbbLogo,
};

const useRatingBadge = () => {
  const ratingData = useMemo(() => {
    const data = DEFAULT_RATING_DATA as unknown as RatingDataProps;
    return Object.entries(data).map(([key, rating]) => {
      const ratingKey = key as RatingKey;
      return {
        ...rating,
        icon: RATING_LOGO_DATA[ratingKey],
        name: ratingKey,
      };
    });
  }, []);

  return {
    ratingData,
  };
};

export default useRatingBadge;
