import React from 'react';
import DataRow from '../../atoms/DataRow';
import './ListerItem.scss';

export interface DataItem {
  title: string;
  value: string | number | JSX.Element;
  defaultValue?: string | number;
}

export interface ListerItemProps extends React.HTMLAttributes<HTMLDivElement> {
  items: DataItem[];
  title?: string;
  buttons?: JSX.Element[];
}

const ListerItem: React.FC<ListerItemProps> = ({ items, title, buttons, className, ...divAttr }) => {
  return (
    <div {...divAttr} className={`${'erg-lister-item'} ${className || ''}`.trim()}>
      {title && (
        <div className={'erg-lister-item__title-row'}>
          <h5>{title}</h5>
          {buttons && (
            <div className={'erg-lister-item__button-row'}>
              {buttons.map((button, index) => (
                <React.Fragment key={index}>{button}</React.Fragment>
              ))}
            </div>
          )}
        </div>
      )}
      {items.map((item, index) => (
        <DataRow
          key={index}
          title={item.title}
          value={item.value}
          defaultValue={item.defaultValue}
        />
      ))}
    </div>
  );
};

export default ListerItem;