import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import ToolButton from '../../atoms/ToolButton';
import iconDropDown from '../../../assets/icon_drop_down.svg';

import './index.scss';
import '../../atoms/ToolButton/index.scss';

/**
 * This is a group of hidden ToolButtons with one embedded ToolButton for
 * switching between collapsed and expanded states.
 */
const ToolbarGroup = (props) => {
  const {
    children,
    className,
    disabled = false,
    titleIcon,
    hint,
    hintPosition = 'right'
  } = props;
  const [expanded, setExpanded] = useState(false);

  useEffect(function avoidExpansionIfDisabled() {
    if (disabled) setExpanded(false);
  }, [disabled, expanded]);

  const wrapperClasses = classNames({
    'toolbar-group': true,
    [className]: className,
  });

  const otherChildrenClasses = classNames({
    'grouped-children': true,
    'visible': expanded,
  });

  const titleButtonClasses = classNames({
    'rotated-title-button-icon': expanded,
  });

  return (
    <div className={wrapperClasses}>
      <div className={otherChildrenClasses}>
        {children}
      </div>
      <div>
        <ToolButton
          className={titleButtonClasses}
          disabled={disabled}
          hint={hint}
          hintPosition={hintPosition}
          icon={expanded ? iconDropDown : titleIcon}
          onClick={() => {
            if (!disabled) {
              setExpanded(!expanded);
            }
          }}
        />
      </div>
    </div>
  );
};

ToolbarGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  hintPosition: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
  titleIcon: PropTypes.node,
};

export default ToolbarGroup;
