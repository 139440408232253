import React, { useState, useCallback, useEffect, HTMLAttributes } from 'react';

import WebShareFallback from './components/WebShareFallback';
import { copyToClipboard, navigatorShare } from 'libs/utils/navigator';

import './index.scss';

export function useWebSharePopupState() {
  const [isVisible, setVisible] = useState(false);

  return {
    isVisible,
    show: () => {
      setVisible(true);
    },
    hide: () => {
      setVisible(false);
    },
  };
}

export interface WebSharePopupProps extends HTMLAttributes<HTMLDivElement>{
  title: string;
  successMessage?: string
  buttonText?: string
  url: string;
  isOpen: boolean;
  onClose: () => void;
}
/**
 * This component tries to use navigator.share if available, otherwise falls back to a popup
 * We need to pass a boolean state to trigger open/close of the popup,
 * this by using a user action trigger (Button mainly)
 * navigator.share will throw an error if we use useEffect without a callback, as this API prevents abuse
 * and requires some user interaction to properly work.
 * @param props {WebSharePopupProps}
 */
const WebSharePopup = (props: WebSharePopupProps) => {
  const { title, url, isOpen, onClose, successMessage, buttonText } = props;

  const [isOpenFallback, setIsOpenFallback] = useState(false);
  const [isSuccessfulCopy, setIsSuccessfulCopy] = useState(false);

  useEffect(() => {
    if (isOpen) {
      onTriggerShareApi();
    }
  }, [isOpen]);

  const onTriggerShareApi = useCallback(() => {
    navigatorShare(title, url)
      .then(() => onCloseModal())
      .catch((error) => {
        // WebShare API throws an error when users dismisses the share modal
        // AbortError: Abort due to cancellation of share.
        if (error.toString().includes('AbortError')) {
          // reset the state
          onClose();
          return;
        }
        // use fallback in case of failure
        setIsSuccessfulCopy(false);
        setIsOpenFallback(true);
      });
  }, [title, url]);

  const onCloseModal = useCallback(() => {
    setIsOpenFallback(false);
    onClose();
  }, [onClose])

  const onCopyUrl = useCallback(() => {
    copyToClipboard(url)
      .then(() => {
        setIsSuccessfulCopy(true);
        onCloseModal();
      });
  }, [url]);

  return (
    <WebShareFallback
      title={title}
      url={url}
      isSuccessfulCopy={isSuccessfulCopy}
      isOpenFallback={isOpenFallback}
      successMessage={successMessage}
      buttonText={buttonText}
      onClose={onCloseModal}
      onCopyUrl={onCopyUrl} />
  )
}

export default WebSharePopup;
