import React from 'react';
import Input from '../../atoms/Input';
import SearchIcon from '../../../assets/icon-search-gray.svg';
import {InputProps} from '../../atoms/Input/types';

import './index.scss';

const SearchInput = (props: InputProps) => {
  const {onChange, value, size = 'small', name, placeholder = 'Search...', label, ...inputAttr} = props;
  const searchIcon = <img className="right-icon" src={SearchIcon}/>;
  return (
    <div className="search-input">
      <Input
        { ...inputAttr }
        name={name}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
        rightIcon={searchIcon}
        size={size}
        value={value}/>
      <br/>
    </div>
  );
};

export default SearchInput;
