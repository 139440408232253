
import React, { useCallback } from 'react';
import { ReactSVG } from 'react-svg';

import IconClose from '../../../../assets/icon-cross-gray.svg';
import IconHeart from '../../../../assets/icon-heart.svg';

import '../index.scss';

export interface ImageInfoProps {
  url: string;
  title: string;
  onClose: () => void;
}

const ImageInfo = (props: ImageInfoProps) => {
  const { url, title, onClose } = props;

  const onImgError = useCallback((e) => {
    // if there is an error loading the image url we can use this fallback to display our ergeon heart
    // instead of showing the broken placeholder image. Happens mostly when sharing non-image URLS.
    e.target.src = IconHeart;
  }, []);

  return (
    <div className="ShareSlide-ImageInfo">
      <img src={url} className="ShareSlide-image" onError={onImgError} alt="" />
      <p className="ShareSlide-title">{title}</p>
      <ReactSVG
        className="ShareSlide-IconClose"
        data-testid="Share-IconClose"
        onClick={onClose}
        src={IconClose} />
    </div>
  )
}

export default ImageInfo;
