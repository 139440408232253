import React, {HTMLAttributes, useMemo} from 'react';
import isNil from 'lodash/isNil';

import IconSuccess from '../../../assets/icon-success.svg';
import IconError from '../../../assets/icon-error.svg';

interface InputLabelProps extends HTMLAttributes<HTMLLabelElement>{
  name?: string;
  label: string;
  valid?: boolean;
}
/**
 * 
 * @param props 
 * @returns A label with an icon attached to input by name.
 */
const InputLabel = (props: InputLabelProps) => {
  const {name, label, valid, ...labelAttr} = props;

  const renderLabelIcon = useMemo(() => {
    if (!isNil(valid)) {
      return <img 
              alt={valid ? 'success icon' : 'error icon'} 
              src={valid ? IconSuccess : IconError} />;
    }
    return <span/>;
  }, [valid]);

  return (
    <label {...labelAttr} className={`erg-input--label`} htmlFor={name}>
      <span className={`erg-input--label-icon`}>{renderLabelIcon}</span>
      <span className={`erg-input--label-content`}>{label}</span>
    </label>
  );
};

export default InputLabel;
