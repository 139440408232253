import React from 'react';

import usePhoneFormatter from '../../../utils/usePhoneFormatter';

import IconPhone from '!@svgr/webpack!../../../../assets/icon-phone-green.svg';

interface PhoneLinkProps {
  phoneNumber?: string
}

const PhoneLink = ({ phoneNumber }: PhoneLinkProps) => {
  const phoneFormatter = usePhoneFormatter(phoneNumber);

  return (
    <a
      className="SimpleTopPanel-phone phone-link"
      data-track-call="true"
      href={phoneFormatter.link}>
        <IconPhone />
        <span>{phoneFormatter.display}</span>
    </a>
  );
};

export default PhoneLink;
