import React, {Dispatch, SetStateAction, useCallback, useMemo} from 'react';

import {ReactSVG} from 'react-svg';

import iconLeftArrow from '../../../../assets/icon-long-arrow-left.svg?url';
import iconRightArrow from '../../../../assets/icon-long-arrow-right.svg?url';
import Button from '../../../atoms/Button/Button';

interface QuestionnaireButtonsProps {
  selectedParamIndex: number;
  setSelectedParamIndex: Dispatch<SetStateAction<number>>;
  isLastParam: boolean;
  onDone: () => void;
  onCancel?: () => void;
  isIndividual?: boolean;
  isLoading?: boolean;
  finishButtonText: string;
  isFinishDisabled?: boolean;
}

const BaseQuestionnaireButtons = (props: QuestionnaireButtonsProps) => {
  const {
    onDone,
    isLastParam,
    selectedParamIndex,
    setSelectedParamIndex,
    onCancel,
    isIndividual,
    isLoading,
    finishButtonText,
    isFinishDisabled: isFinishDisabledProp,
  } = props;

  const handleClickBack = useCallback(() => {
    setSelectedParamIndex((index) => index - 1);
  }, []);

  const handleClickNext = useCallback(() => {
    if (isLastParam) {
      onDone();
    } else {
      setSelectedParamIndex((index) => index + 1);
    }
  }, [isLastParam, onDone]);

  const isBackDisabled = useMemo(() => selectedParamIndex === 0 || isLoading, [selectedParamIndex, isLoading]);

  const isFinishDisabled = useMemo(() => {
    return isLoading || (isLastParam && isFinishDisabledProp);
  }, [isFinishDisabledProp, isLastParam, isLoading]);

  if (isIndividual) {
    return (
      <div className="base-questionnaire__buttons-container">
        <Button
          className="base-questionnaire__button"
          disabled={isLoading}
          flavor="regular"
          onClick={onCancel}
          size="large"
          taste="line"
        >
          Cancel
        </Button>
        <Button className="base-questionnaire__button" disabled={isLoading} onClick={onDone} size="large">
          Done
        </Button>
      </div>
    );
  }

  return (
    <div className="base-questionnaire__buttons-container">
      <Button
        className="base-questionnaire__button"
        disabled={isBackDisabled}
        flavor="regular"
        onClick={handleClickBack}
        size="large"
        taste="line"
      >
        <ReactSVG className="base-questionnaire__left-arrow" src={iconLeftArrow} />
        Back
      </Button>
      <Button className="base-questionnaire__button" disabled={isFinishDisabled} onClick={handleClickNext} size="large">
        {isLastParam ? finishButtonText : 'Next'}
        {!isLastParam && <ReactSVG className="base-questionnaire__right-arrow" src={iconRightArrow} />}
      </Button>
    </div>
  );
};

export default BaseQuestionnaireButtons;
