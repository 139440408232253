import React, { useMemo } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import moment from 'moment';

import LeftArrow from '!@svgr/webpack!../../../assets/icon-arrow-left.svg';
import RightArrow from '!@svgr/webpack!../../../assets/icon-arrow-right.svg';

type Locale = { locale: string };

function DatePickerNav(props: ReactDatePickerCustomHeaderProps & Locale) {
  const { date, decreaseMonth, increaseMonth, locale } = props;

  const [prev, next] = useMemo(() => [-1, 1].map(
    diff => moment(date).locale(locale).add(diff, 'month').format('MMM \'YY'),
  ), [date, locale]);

  return (
    <div className="CalendarNav">
      <button className="CalendarNav-button is-left" onClick={decreaseMonth}>
        <LeftArrow />{prev}
      </button>
      <button className="CalendarNav-button is-right" onClick={increaseMonth}>
        {next}<RightArrow />
      </button>
    </div>
  );
}

export default DatePickerNav;
